<template>
    <div >
      <h4 style="text-align: center">Preço Automático</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>Margem</th>
            <th>Free Shipping</th>
            <th>% Pagamento</th>
            <th>Preço (Kg)</th>
            <th>Multiplicação</th>
            <th>Soma</th>
            <th></th>
          </tr>
          <tr>
            <td>Idivia</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.free_shipping" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.payment_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.kg_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.mult_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.idivia.sum_price" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceVariables(priceAutoVariables.idivia, 'Idivia')">🔄</a>
            </td>
          </tr>
          <tr>
            <td>Kiooli</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.free_shipping" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.payment_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.kg_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.mult_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariables.kiooli.sum_price" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceVariables(priceAutoVariables.kiooli, 'Kiooli')">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <hr />
      <h4 style="text-align: center">Intervalos</h4>
      <hr />
      <div style="text-align: center">
        <b-button class="shipping" @click="changeIntervalos('idivia')">Idivia</b-button>
        <b-button class="autoPrice" @click="changeIntervalos('kiooli')">Kiooli</b-button>
      </div>
      <hr />
      <div style="text-align: center">
        <b-button class="shipping" @click="openModal()">➕</b-button>
      </div>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>€ Inicial</th>
            <th>€ Final</th>
            <th>€ Mult.</th>
            <th>€ Soma</th>
            <th>€ Fixo</th>
            <th>P. Inicial</th>
            <th>P. Final</th>
            <th>P. Mult.</th>
            <th>P. Soma</th>
            <th>P. Fixo</th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="inter in intervalos_show" :key="inter.id">
            <td>{{ inter.site }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_initial" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_final" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_mult" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_sum" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_fix" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_initial" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_final" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_mult" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_sum" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_fix" />
            </td>
            <td>
              <a id="updateValues" @click="updateIntervalo(inter)">🔄</a>
            </td>
            <td>
              <a id="updateValues" @click="deleteIntervalo(inter)">❌</a>
            </td>
          </tr>
        </table>
      </div>
      <b-modal ref="my-modal" id="modal-1" title="Adicionar Intervalo" hide-footer>
      <form v-on:submit.prevent="addIntervalo()">
        <div class="form-group">
          <label for="addressInput">€ Inicial:</label>
          <input type="text" class="form-control" id="addressInput" v-model="price_initial" />
        </div>
        <div class="form-group">
          <label for="cityInput">€ Final:</label>
          <input type="text" class="form-control" id="cityInput" v-model="price_final" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">€ Mult. :</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="price_mult" />
        </div>
        <div class="form-group">
          <label for="stateInput">€ Soma:</label>
          <input type="text" class="form-control" id="stateInput" v-model="price_sum" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">€ Fixo:</label>
          <input type="text" class="form-control" id="stateInput" v-model="price_fix" />
        </div>
        <div class="form-group">
          <label for="addressInput">Peso Inicial:</label>
          <input type="text" class="form-control" id="addressInput" v-model="weight_initial" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Final:</label>
          <input type="text" class="form-control" id="cityInput" v-model="weight_final" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Peso Mult. :</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="weight_mult" />
        </div>
        <div class="form-group">
          <label for="stateInput">Peso Soma:</label>
          <input type="text" class="form-control" id="stateInput" v-model="weight_sum" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Peso Fixo:</label>
          <input type="text" class="form-control" id="stateInput" v-model="weight_fix" />
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Adicionar Intervalo</button>
        </div>
      </form>
    </b-modal>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  
  export default {
      data: function(){
          return{
              priceAutoVariables: {
                idivia: {
                  free_shipping: "",
                  kg_price: "",
                  margin: "",
                  mult_price: "",
                  payment_perc: "",
                  site: "",
                  sum_price: "",
                },
                kiooli: {
                  free_shipping: "",
                  kg_price: "",
                  margin: "",
                  mult_price: "",
                  payment_perc: "",
                  site: "",
                  sum_price: "",
                }
              },
              intervalos_show: [],
              intervalo: '',
              intervalos: [],
              //Modal
              price_initial: '',
              price_final: '',
              price_mult: '',
              price_sum: '',
              price_fix: '',
              weight_initial: '',
              weight_final: '',
              weight_mult: '',
              weight_sum: '',
              weight_fix: '',
          }
      },
      methods: {
          async deleteIntervalo(inter) {
              this.$swal({
                  title: 'Quer eliminar o intervalo?',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim, eliminar!',
                  cancelButtonText: 'Cancelar',
              }).then(async (result) => {
                  if (result.isConfirmed) {
                  try {
                      await this.$store.dispatch('deletePriceIntervalos', {
                      id: inter.id,
                      });
                      this.$swal({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      title: `Intervalo eliminado`,
                      showConfirmButton: false,
                      timer: 1500,
                      });
                      await this.getIntervalos();
                  } catch (error) {
                      alert(error);
                  }
                  }
              });
          },
          async updateIntervalo(inter) {
              this.$swal({
                  title: 'Quer atualizar o intervalo?',
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim, atualizar!',
                  cancelButtonText: 'Cancelar',
              }).then(async (result) => {
                  if (result.isConfirmed) {
                  try {
                      await this.$store.dispatch('updatePriceIntervalos', {
                      id: inter.id,
                      price_initial: inter.price_initial ?? null,
                      price_final: inter.price_final ?? null,
                      price_mult: inter.price_mult ?? null,
                      price_sum: inter.price_sum ?? null,
                      price_fix: inter.price_fix ?? null,
                      weight_initial: inter.weight_initial ?? null,
                      weight_final: inter.weight_final ?? null,
                      weight_mult: inter.weight_mult ?? null,
                      weight_sum: inter.weight_sum ?? null,
                      weight_fix: inter.weight_fix ?? null,
                      });
                      this.$swal({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      title: `Intervalo atualizado`,
                      showConfirmButton: false,
                      timer: 1500,
                      });
                      await this.getIntervalos();
                  } catch (error) {
                      alert(error);
                  }
                  }
              });
          },
          changeIntervalos(site) {
              this.intervalo = site;
              this.intervalos_show = this.intervalos.filter((item) => item.site === this.intervalo);
          },
          async openModal() {
              this.$refs['my-modal'].show();
          },
          async updatePriceVariables(site, name) {
            if (site.mult_price < 0.95 || site.sum_price < 0) {
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: `Valores não válidos.`,
                showConfirmButton: false,
                timer: 1500,
              });
              return;
            }
            try {
              await this.$store.dispatch('updatePriceVariables', {
                site: site.site,
                margin: site.margin,
                free_shipping: site.free_shipping,
                payment_perc: site.payment_perc,
                kg_price: site.kg_price,
                mult_price: site.mult_price,
                sum_price: site.sum_price,
              });
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: `Variáveis ${name} atualizado`,
                showConfirmButton: false,
                timer: 1500,
              });
            } catch (err) {
              alert(err);
            }
          },
          async addIntervalo() {
            try {
              await this.$store.dispatch('addPriceIntervalos', {
                price_initial: this.price_initial ?? null,
                price_final: this.price_final ?? null,
                price_mult: this.price_mult ?? null,
                price_sum: this.price_sum ?? null,
                price_fix: this.price_fix ?? null,
                weight_initial: this.weight_initial ?? null,
                weight_final: this.weight_final ?? null,
                weight_mult: this.weight_mult ?? null,
                weight_sum: this.weight_sum ?? null,
                weight_fix: this.weight_fix ?? null,
                site: this.intervalo,
              });
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: `Intervalo Adicionado`,
                showConfirmButton: false,
                timer: 1500,
              });
              await this.getIntervalos();
              this.$refs['my-modal'].hide();
            } catch (error) {
              alert(error);
            }
          },
          async getIntervalos() {
            try {
              await this.$store.dispatch('getPriceIntervalos');
              this.intervalos = this.getPriceIntervalos;
              this.intervalos_show = this.intervalos.filter((item) => item.site === this.intervalo);
            } catch (err) {
              alert(err);
            }
          },
          async getPrice() {
            try {
              await this.$store.dispatch('getPriceVariables');
              this.priceAutoVariables = this.getPriceVariables;
              console.log('2', this.getPriceVariables);
            } catch (err) {
              alert(err);
            }
          },
          async loadData(){
            this.intervalo = 'idivia';
            await this.getPrice();
            await this.getIntervalos();
          }
      },
      created(){
        this.loadData();
      },
      computed: {
        ...mapGetters(['getPriceIntervalos', 'getPriceVariables']),
      }
  }
</script>

<style>

</style>